import React from "react";
import {connect} from "react-redux";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className="background-img"
                    style={{
                        // backgroundImage: "url('img/hero-bg-1.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-12">
                                <div className="text-white">
                                    <p className="lead">
                                        Het team Arbeidsrecht van TK bestaat uit gespecialiseerde arbeidsrechtadvocaten,
                                        die veel kennis en ervaring hebben op het gebied van ontslag. Het team
                                        Arbeidsrecht bedient binnen TK alle sectorteams en haar cliënten van haar
                                        expertise.<br/>
                                        <br/>
                                        Ons team helpt u graag verder met de praktische toepassing van de rekentool, het
                                        opstellen van de vaststellingsovereenkomst en andere arbeidsrecht gerelateerd
                                        zaken. Neem gerust vrijblijvend contact met ons op. Ook wanneer u advies nodig
                                        heeft over een dreigend ontslag, zijn wij u graag van dienst.<br/>
                                        <br/>
                                        TK staat voor hoogwaardige juridische dienstverlening die de klant in staat
                                        stelt om direct door te gaan met zijn of haar eigen zaken.<br/>
                                        <br/>
                                        <br/>
                                        TK - Zodat u direct verder kunt.<br/>
                                    </p>
                                    <p><br/><br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-img-absolute">
                        <img src="img/white-bg1.svg" alt="wave shape" className="img-fluid"/>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state,
}))(About);
