import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5">
                  <h2>Algemene voorwaarden</h2>
                  <p className="lead">
                    Hier vindt u de voorwaarden voor het gebruik van de TK transitie vergoeding applicatie.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div id="accordion-1" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Algemeen
                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          De overeenkomst die tussen u en van Benthem & Keulen met betrekking tot de App tot stand komt heeft uitsluitend het gebruik van de App tot voorwerp. Uitdrukkelijk komt er geen overeenkomst van opdracht tussen u en VBK tot stand tot het verlenen van juridische diensten. Aldus bent u door enkel gebruikmaking van de App geen cliënt en is VBK niet uw advocaat. De VBK App is alleen bestemd voor gebruik door personen die onder gezag van de werkgever en met diens goedkeuring de VBK App gebruiken voor berekeningen met betrekking tot betrokkenen (degene op wie de (persoons)gegevens betrekking hebben). Als u aan het voorgaande niet kunt voldoen kunt u mogelijk aansprakelijk zijn jegens de werkgever van betrokkene en de betrokkene zelf indien u onrechtmatig (i) persoonsgegevens verwerkt van de betrokkene; (ii) gebruik maakt van deze App.<br/>
                          <br/>
                          De VBK App is een initiatief van VBK, waarbij u de mogelijkheid krijgt om de ontslagvergoeding te berekenen van werknemers. De informatie in deze App is geen vervanging van de informatie die door advocaten en andere juridische specialisten wordt verstrekt. De App geeft nadrukkelijk geen juridisch advies, maar biedt slechts een hulpmiddel bij de berekening van de ontslagvergoeding van een werknemer. Voor gerichte advisering wordt u verzocht contact op te nemen met één van onze specialisten.<br/>
                          <br/>
                          De eerste keer dat u de VBK App gebruikt, dient u deze gebruiksvoorwaarden integraal te accepteren. Door acceptatie van deze gebruiksvoorwaarden verbindt u zichzelf en de werkgever van de betrokkene aan deze gebruiksvoorwaarden. Zonder deze acceptatie krijgt u geen toegang tot de inhoud van de VBK App.<br/>
                          <br/>
                          Tevens moet u een PINcode kiezen, zodat alleen u toegang heeft tot de App op uw apparaat. Deze PINcode dient u geheim te houden: u staat in voor het gebruik dat van deze PINcode gemaakt wordt. De door u gekozen PINcode wordt ook gebruikt om toegang te krijgen tot door u met behulp van de App gemaakte berekeningen die u in een met de PINcode beveiligde PDF verstuurt van uw apparaat naar een ander apparaat zoals een PC, tablet of laptop. De App is zo ingeregeld dat na 5 mislukte pogingen alle opgeslagen gegevens worden verwijderd.<br/>
                          <br/>
                          VBK heeft geen toegang tot persoonsgegevens van de werknemer. Nadat alle benodigde informatie, zoals het salaris en de dienstjaren van de werknemer, om de ontslagvergoeding te berekenen zijn ingevuld, geeft de VBK App de mogelijkheid om deze berekening beveiligd op te slaan op uw apparaat en/of in beveiligd pdf-formaat met gebruik van uw eigen e-mail applicatie per e-mail (naar uzelf) te verzenden. Hiervoor dient u ten minste uw e-mailadres op te geven. De door u verstrekte gegevens van de betrokkene worden alleen verwerkt om u te voorzien van het resultaat van de berekening in pdf-formaat. Deze PDF wordt niet op de App vervaardigd maar door een systeem van VBK. Meteen na het vervaardigen van de PDF worden de gegevens waarmee de PDF gemaakt is verwijderd van het VBK systeem.<br/>
                          <br/>
                          Doordat u de App download en uw contact gegevens invoert heeft VBK de mogelijkheid met u contact op te nemen om u te informeren over haar (nieuwe) producten en diensten. Ook kan VBK met u contact opnemen indien u een berekening gemaakt heeft. VBK kent zoals hierboven aangegeven geen verdere details over de berekening. Ons privacy statement informeert u verder hoe VBK uw gegevens verwerkt.  Ten slotte kunnen de gegevens die u verstrekt nog worden verwerkt indien hiertoe een wettelijke verplichting bestaat voor VBK.<br/>

                          user.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-gallery mr-3"></span> Aansprakelijkheid
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          De overeenkomst verplicht VBK niet dat de App foutloos functioneert en dat de berekeningen in alle gevallen correct zullen zijn: de App is slechts  een hulpmiddel en is niet - en vervangt niet - een juridisch advies inzake de hoogte van de transitievergoeding.<br/>
                          VBK is niet aansprakelijk voor schade die voortvloeit uit het gebruik van de VBK App, waaronder maar niet beperkt tot schade ontstaan als gevolg van eventuele fouten, onvolledigheden, storingen of onderbrekingen in de VBK App.<br/>
                          <br/>
                          U aanvaardt aansprakelijkheid en zal VBK vrijwaren ter zake van alle aanspraken van de personen met betrekking tot welke u de VBK App toepast. In het bijzonder zal u er voor instaan dat u en de onderneming die u bindt door gebruikmaking van de VBK App conform de verplichtingen uit de Wet bescherming persoonsgegevens zal handelen, waaronder het nemen van passende technische en organisatorische maatregelen om de privacy van de persoon op wie de berekening met behulp van deze App betrekking heeft te beschermen.<br/>
                          U bent ermee bekend en aanvaardt dat VBK het recht heeft om op ieder moment de beschikbaarheid van de VBK App te onderbreken voor onderhoud of updates of te beëindigen.<br/>

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-wallet mr-3"></span> Intellectuele eigendom
                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                          Alle (intellectuele eigendoms)rechten – waaronder in ieder geval begrepen de merkrechten, domeinnamen en auteursrechten – die rusten op of voortvloeien uit de inhoud en/of onderdelen van de inhoud in de VBK App – waaronder in ieder geval begrepen de afbeeldingen, teksten, vormgeving en overig (beeld)materiaal – berusten bij VBK of haar licentiegevers.<br/>
                          <br/>
                          Ieder gebruik van de VBK App en/of (delen) van haar inhoud voor commerciële doeleinden is niet toegestaan, zonder de voorafgaande schriftelijke en uitdrukkelijke toestemming van VBK. Het is eveneens verboden om de informatie zoals weergegeven in de VBK App te gebruiken voor onrechtmatige doeleinden.<br/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="accordion-2" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-1"
                      aria-expanded="false"
                      aria-controls="collapse-2-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Toepasselijk recht en bevoegdheid rechter
                      </h6>
                    </div>
                    <div
                      id="collapse-2-1"
                      className="collapse"
                      aria-labelledby="heading-2-1"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Nederlands recht is van toepassing. De bevoegde rechter in de Rechtbank Midden-Nederland is uitsluitend bevoegd om van de geschillen kennis te nemen die ontstaan in het kader van het gebruik van de VBK App.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-2"
                      aria-expanded="false"
                      aria-controls="collapse-2-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-lock mr-3"></span> Vragen of opmerkingen?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-2"
                      className="collapse"
                      aria-labelledby="heading-2-2"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Heeft u vragen, klachten of opmerkingen over de VBK App, neemt u dan contact op met Carida Kleijn, marketing manager, via email: caridakleijn@vbk.nl of via telefoonnummer: 030 - 2595600.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

export default Faq;
