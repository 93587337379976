import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionSignUp1";
import FooterSection from "../components/Footer";

class SignUp extends Component {
  render() {
    return (
      <React.Fragment>
        <Header bgColor="#4c262d" />
        <div className="main">
          <HeroSection />
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default SignUp;
