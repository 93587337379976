import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionFaq";
import FooterSection from "../components/Footer";
import Contact from "../components/Contact/Contact";

class ContactUs extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="main">
                    <HeroSection title={"Contact"}/>
                    <Contact />
                </div>
                <FooterSection noSubscription={true} />
            </React.Fragment>
        );
    }
}

export default ContactUs;
