import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 " + (this.props.noSubscription ? 'py-5' : 'pt-150 pb-5')} 
            style={{
              // backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover",
              backgroundColor: "white",
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap">
                    <img
                      src="img/tk-logo-tk.png"
                      alt="footer logo"
                      width="80"
                      className="img-fluid mb-3"
                    />
                    <p>
                      Uw vraag omzetten naar juridische oplossingen.
                    </p>

                    {/*<div className="social-list-wrap">*/}
                    {/*  <ul className="social-list list-inline list-unstyled">*/}
                    {/*    <li className="list-inline-item">*/}
                    {/*      <a href="/#" target="_blank" title="Facebook">*/}
                    {/*        <span className="ti-facebook"></span>*/}
                    {/*      </a>*/}
                    {/*    </li>*/}
                    {/*    <li className="list-inline-item">*/}
                    {/*      <a href="/#" target="_blank" title="Twitter">*/}
                    {/*        <span className="ti-twitter"></span>*/}
                    {/*      </a>*/}
                    {/*    </li>*/}
                    {/*    <li className="list-inline-item">*/}
                    {/*      <a href="/#" target="_blank" title="Instagram">*/}
                    {/*        <span className="ti-instagram"></span>*/}
                    {/*      </a>*/}
                    {/*    </li>*/}
                    {/*    <li className="list-inline-item">*/}
                    {/*      <a href="/#" target="_blank" title="printerst">*/}
                    {/*        <span className="ti-pinterest"></span>*/}
                    {/*      </a>*/}
                    {/*    </li>*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap">
                    <h5 className="mb-3">Links</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/over-ons">Over ons</a>
                      </li>
                      <li className="mb-2">
                        <a href="/contact">Contact</a>
                      </li>
                      {/*<li className="mb-2">*/}
                      {/*  <a href="/#">Privacy Policy</a>*/}
                      {/*</li>*/}
                      {/*<li className="mb-2">*/}
                      {/*  <a href="/voorwaarden">Algemene voorwaarden</a>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap">
                    <h5 className="mb-3">Contact</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Vondellaan 51
                        <br />
                        2332 AA Leiden
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+31715358000"> +31 71 535 80 00</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:info@tk.nl"> info@tk.nl</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="https://www.tk.nl" rel="noopener noreferrer" target="_blank"> www.tk.nl</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights © 2020. All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
