import {
  SUBSCRIBE,
  SUBMIT_CONTACT,
  POST_PROMO,
  GET_QUOTE,
  POST_LOGIN,
  POST_SIGNUP,
  POST_RESET_PASSWORD,
  POST_CHANGE_PASSWORD,
  GET_SEARCH,
  POST_COMMENT,
} from "../constants/types.js";
import axios from "axios";


export const subscribe = email => {
  return {
    type: SUBSCRIBE,
    email,
  };
};

export const submitContact = contactData => {
  return {
    type: SUBMIT_CONTACT,
    contactData,
  };
};

export const postPromo = promoData => {
  return {
    type: POST_PROMO,
    promoData,
  };
};

export const getQuote = quoteData => {
  return {
    type: GET_QUOTE,
    quoteData,
  };
};

export const login = loginData => {
  return {
    type: POST_LOGIN,
    loginData,
  };
};

export const submitSignUp = signUpData => {
  return dispatch => {
    const postData = {
      email: signUpData.email,
      fullname: signUpData.name,
      emp_birth_date: signUpData.birthDate,
      emp_start_date: signUpData.startDate,
      emp_end_date: signUpData.endDate,
      monthly_salary: signUpData.monthlySalary,
      holiday_bonus: signUpData.holidayBonus,
      yearly_bonus: signUpData.fixedYearlyBonus,
      over_time: signUpData.overTime,
      var_yearly_bonus: signUpData.varYearlyBonus
    }

    const urlProd = 'https://tk-transitie-api.asteco.nl/api/calculation_request/';
    // const urlProd = 'https://transitievergoeding-api.asteco.nl/api/calculation_request/';
    // const urlDev = 'http://localhost:25080/api/calculation_request/';

    axios.post(urlProd, postData)
        .then(res => {
          console.log(res.data);
          dispatch(signUp(signUpData));
        })
        .catch(err => {
        });
  };
};

export const signUp = signUpData => {
  return {
    type: POST_SIGNUP,
    signUpData,
  };
};

export const resetPassword = resetPasswordData => {
  return {
    type: POST_RESET_PASSWORD,
    resetPasswordData,
  };
};

export const changePassword = changePasswordData => {
  return {
    type: POST_CHANGE_PASSWORD,
    changePasswordData,
  };
};

export const search = searchData => {
  return {
    type: GET_SEARCH,
    searchData,
  };
};

export const comment = commentData => {
  return {
    type: POST_COMMENT,
    commentData,
  };
};
