import React from "react";
import {connect} from "react-redux";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className="background-img"
                    style={{
                        // backgroundImage: "url('img/hero-bg-1.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6">
                                <div className="text-white">
                                    <p className="lead">
                                        TK staat voor hoogwaardige juridische dienstverlening die de klant in staat
                                        stelt om direct door te gaan met zijn of haar eigen zaken. Niet voor niets luidt
                                        ons motto “Zodat u direct verder kunt."<br/>
                                        <br/>
                                        Heeft u vragen op het gebied van arbeidsrecht of andere juridische zaken, wenst
                                        u een toelichting of kunnen wij u direct verder helpen? Neem dan contact op met
                                        het team Arbeidsrecht van TK.<br/>

                                    </p>
                                    <p><br/><br/></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-white">
                                    <p className="lead">
                                        <b>Algemeen:</b><br/>
                                        <span className="ti-mobile mr-2"></span><a href="tel:+31705358000">+31 70 535 80 00</a><br/>
                                        <span className="ti-email mr-2"></span><a href="mailto:info@tk.nl">INFO@TK.NL</a><br/>
                                        <br/>
                                        <b>Arbeidsrecht:</b><br/>
                                        <span className="ti-mobile mr-2"></span><a href="tel:+31705358086">+31 70 535 80 86</a><br/>
                                        <span className="ti-email mr-2"></span><a href="mailto:arbeidsrecht@tk.nl">ARBEIDSRECHT@TK.NL</a><br/>
                                        <br/>
                                        <b>JEFFREY KENENS</b><br/>
                                        <span className="ti-mobile mr-2"></span><a href="tel:+31622564959">+31 6 22 56 49 59</a><br/>
                                        <span className="ti-email mr-2"></span><a href="mailto:kenens@tk.nl">KENENS@TK.NL</a><br/>
                                        <br/>
                                        <b>RENÉ NIEUWMANS</b><br/>
                                        <span className="ti-mobile mr-2"></span><a href="tel:+31651375093">+31 6 51 37 50 93</a><br/>
                                        <span className="ti-email mr-2"></span><a href="mailto:nieuwmans@tk.nl">NIEUWMANS@TK.NL</a><br/>

                                    </p>
                                    <p><br/><br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-img-absolute">
                        <img src="img/white-bg1.svg" alt="wave shape" className="img-fluid"/>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state,
}))(Contact);
