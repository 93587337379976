import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionFaq";
import About from "../components/AboutUs/AboutUs";
import FooterSection from "../components/Footer";

class AboutUs extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="main">
                    <HeroSection title={"Over ons"}/>
                    <About />
                </div>
                <FooterSection noSubscription={true} />
            </React.Fragment>
        );
    }
}

export default AboutUs;
