import React from "react";
import {connect} from "react-redux";
import {submitSignUp} from "../../actions/index";
import {Redirect} from "react-router-dom"

class HeroSection extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            hero: {},
            name: "",
            email: "",
            birthDate: "",
            startDate: "",
            endDate: "",
            monthlySalary: "",
            holidayBonus: false,
            fixedYearlyBonus: "",
            overTime: "",
            varYearlyBonus: "",
            disableBtn: false,
            btnText: "Bereken",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeBtnText = btnText => {
        if (this._isMounted) {
            this.setState({btnText});
        }
    };

    handleFormValueChange(inputName, event) {
        let stateValue = {};
        stateValue[inputName] =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        if (this._isMounted) {
            this.setState(stateValue);
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        // disable the button
        if (this._isMounted) {
            this.setState({disableBtn: true});
        }

        // // get action
        const signUpAction = submitSignUp(this.state);

        // // Dispatch the contact from data
        this.props.dispatch(signUpAction);

        // // added delay to change button text to previous
        setTimeout(
            function () {
                // enable the button
                if (this._isMounted) {
                    this.setState({disableBtn: false});
                }

                // change to button name
                this.changeBtnText("Sign up");

                // clear form data
                if (this._isMounted) {
                    this.setState({
                        name: "",
                        email: "",
                        birthDate: "",
                        startDate: "",
                        endDate: "",
                        monthlySalary: "",
                        holidayBonus: false,
                        fixedYearlyBonus: "",
                        overTime: "",
                        varYearlyBonus: "",
                        disableBtn: false,
                        btnText: "Bereken",
                    });
                }
            }.bind(this),
            3000
        );
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.props.state.signup[0] && this.props.state.signup[0].postSuccess) {
            return (<Redirect to={'/thankyou'}/>);
        }
        return (
            <React.Fragment>
                <section
                    className="hero-section ptb-100 background-img full-screen"
                    style={{
                        // backgroundImage: "url('img/hero-bg-1.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
                            <div className="col-md-5 col-lg-5">
                                <div className="card login-signup-card shadow-lg mb-0">
                                    <div className="card-body px-md-5 py-5">
                                        <div className="mb-5">
                                            <h6 className="h3">Gegevens</h6>
                                            <p className="text-muted mb-0">
                                                Vul hier de gegevens in. U ontvangt de transitievergoeding per e-mail.
                                                TK ontvangt alleen de naam en het e-mailadres van de aanvrager. Deze
                                                wordt alleen gebruikt voor een eenmalige navraag over het gebruik van
                                                deze tool.
                                            </p>
                                        </div>
                                        <form
                                            className="login-signup-form"
                                            method="post"
                                            onSubmit={this.handleSubmit}
                                        >
                                            <div className="form-group">
                                                <label className="pb-1">Naam aanvrager</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-user color-primary"></span>
                                                    </div>

                                                    <input
                                                        value={this.state.name}
                                                        onChange={e =>
                                                            this.handleFormValueChange("name", e)
                                                        }
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="John Doe"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="pb-1">E-mailadres aanvrager</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-email color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.email}
                                                        onChange={e =>
                                                            this.handleFormValueChange("email", e)
                                                        }
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="name@yourdomain.com"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="pb-1">Geboortedatum werknemer</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.birthDate}
                                                        onChange={e =>
                                                            this.handleFormValueChange("birthDate", e)
                                                        }
                                                        type="date"
                                                        name="birthdate"
                                                        className="form-control"
                                                        placeholder=""
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="pb-1">Startdatum</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.startDate}
                                                        onChange={e =>
                                                            this.handleFormValueChange("startDate", e)
                                                        }
                                                        type="date"
                                                        name="startdate"
                                                        className="form-control"
                                                        placeholder=""
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="pb-1">Einddatum</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.endDate}
                                                        onChange={e =>
                                                            this.handleFormValueChange("endDate", e)
                                                        }
                                                        type="date"
                                                        name="enddate"
                                                        className="form-control"
                                                        placeholder=""
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="pb-1">Bruto salaris (per maand)</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.monthlySalary}
                                                        onChange={e =>
                                                            this.handleFormValueChange("monthlySalary", e)
                                                        }
                                                        type="text"
                                                        name="monthlysalary"
                                                        className="form-control"
                                                        placeholder="3000"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="my-4">
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input
                                                        checked={this.state.holidayBonus}
                                                        onChange={e =>
                                                            this.handleFormValueChange("holidayBonus", e)
                                                        }
                                                        type="checkbox"
                                                        name="holidayBonus"
                                                        className="custom-control-input"
                                                        id="ckbholidayBonus"
                                                    />
                                                    <label className="custom-control-label" htmlFor="ckbholidayBonus"
                                                           data-toggle="tooltip" data-html="true" title="In de berekening wordt uitgegaan van 8% vakantietoeslag">
                                                        Vakantietoeslag (?)
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="pb-1" data-toggle="tooltip" data-html="true"
                                                       title="De vaste eindejaarsuitkering waar de werknemer binnen twaalf maanden aanspraak op zou hebben bij voortzetting van de arbeidsovereenkomst, gedeeld door twaalf">
                                                  Vaste eindejaarsuitkering (?)</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.fixedYearlyBonus}
                                                        onChange={e =>
                                                            this.handleFormValueChange("fixedYearlyBonus", e)
                                                        }
                                                        type="text"
                                                        name="fixedYearlybonus"
                                                        className="form-control"
                                                        placeholder="750"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="pb-1" data-toggle="tooltip" data-html="true"
                                                       title="Indien overwerk en/of ploegentoeslag geen vast looncomponent per maand is, gebruikt u de gemiddelde toeslag per maand van de 12 maanden voorafgaand aan einde dienstverband. U telt het totaal van de 12 voorafgaande maanden op en deelt dat door 12.">
                                                  Overwerk / ploegentoeslag (?)</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.overTime}
                                                        onChange={e =>
                                                            this.handleFormValueChange("overTime", e)
                                                        }
                                                        type="text"
                                                        name="overTime"
                                                        className="form-control"
                                                        placeholder="500"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="pb-1" data-toggle="tooltip" data-html="true"
                                                       title="De overeengekomen variabele looncomponenten verschuldigd in de drie kalenderjaren voorafgaande aan het jaar waarin de arbeidsovereenkomst eindigt, gedeeld door zesendertig. Het gaat hier om bonus, winstuitkering en variabele eindejaarsuitkering.<br/>
                                                       <br/>
                                                       Het getal, waardoor het bedrag aan verschuldigde looncomponenten wordt gedeeld, wordt naar rato aangepast indien de duur van de arbeidsovereenkomst korter was dan de periode van 36 maanden.<br/>
                                                       <br/>
                                                       Als is overeengekomen dat het loon van de werknemer geheel of gedeeltelijk bestaat uit provisie, dan wordt het deel van het bruto loon over twaalf maanden dat uit provisie bestond opgeteld, en vervolgens gedeeld door twaalf.<br/>
                                                       <br/>
                                                       Indien de duur van de arbeidsovereenkomst die wordt beëindigd korter dan een jaar was, wordt dit naar rato toegepast.">
                                                  Variabele looncomponenten (?)</label>
                                                <div className="input-group input-group-merge">
                                                    <div className="input-icon">
                                                        <span className="ti-lock color-primary"></span>
                                                    </div>
                                                    <input
                                                        value={this.state.varYearlyBonus}
                                                        onChange={e =>
                                                            this.handleFormValueChange("varYearlyBonus", e)
                                                        }
                                                        type="text"
                                                        name="varYearlyBonus"
                                                        className="form-control"
                                                        placeholder="250"
                                                    />
                                                </div>
                                            </div>

                                            <button
                                                className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                                                id="signIn"
                                                disabled={this.state.disableBtn}
                                                onClick={() => {
                                                    this.changeBtnText("Versturen...");
                                                }}
                                            >
                                                {this.state.btnText}
                                            </button>
                                        </form>
                                    </div>
                                    {/*<div className="card-footer px-md-5 bg-transparent border-top">*/}
                                    {/*  <small>Bekijk <a href="voorwaarden" className="small">hier</a> onze algemene voorwaarden </small>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <div className="text-white">
                                    <h1 className="text-white">Transitievergoeding</h1>
                                    <p className="lead">
                                        Met deze tool kunt u eenvoudig en direct in enkele stappen de wettelijke
                                        transitievergoeding berekenen.<br/>
                                        <br/>
                                        Wanneer de werkgever en een werknemer in onderling overleg besluiten om de
                                        arbeidsovereenkomst te beëindigen, dan wordt in de regel ook een vergoeding aan
                                        de werknemer betaald. Hoewel de werknemer formeel dan geen recht heeft op de
                                        wettelijke transitievergoeding, wordt daar in de praktijk wel bij
                                        aangehaakt.<br/>
                                        <br/>
                                        Deze tool berekent voor u welke wettelijke transitievergoeding er geldt.<br/>
                                        <br/>
                                        Nadat u alle gegevens hebt ingevuld, ontvangt u de berekening per e-mail in een
                                        PDF bestand.<br/>
                                        <br/>
                                        <h5 className="text-white">Voorwaarden transitievergoeding</h5>
                                        <br/>
                                        Bij ontslag kan een werknemer recht hebben op een transitievergoeding. De
                                        transitievergoeding is een vergoeding die de werkgever aan de werknemer betaalt.<br/>
                                        <br/>
                                        Recht op een transitievergoeding bestaat als de werknemer wordt ontslagen of als
                                        het tijdelijke contract niet wordt verlengd, op initiatief van de werkgever. Dit
                                        recht bestaat ook als de werknemer zelf ontslag neemt wegens ernstig verwijtbaar
                                        handelen of nalaten van de werkgever. Zelfs in geval van ontslag op staande
                                        voet, kan de werkgever een transitievergoeding verschuldigd zijn, tenzij sprake
                                        is van ernstig verwijtbaar handelen of nalaten van de werknemer.<br/>
                                        <br/>
                                        Partijen bepalen zelf onder welke voorwaarden de arbeidsovereenkomst eindigt.
                                        Deze voorwaarden worden opgenomen in een zogenaamde
                                        vaststellingsovereenkomst.<br/>
                                    </p>
                                    <p><br/><br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-img-absolute">
                        <img src="img/wave-shap.svg" alt="wave shape" className="img-fluid"/>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state,
}))(HeroSection);
